<template>
  <div class="job-card center text-align--left">
    <div class="job-card__body">
      <div class="job-card__text">
        <h3 class="job-card__title">{{ title }}</h3>
        <span class="job-card__job-description">{{ description }}</span>
      </div>
      <div class="job-card__icon">
        <img :src="icon" :alt="title" />
      </div>
      <div v-if="tags.length" class="job-card__tag-area">
        <span v-for="tag in tags" :key="tag" class="job-card__tag">{{
          tag
        }}</span>
      </div>
      <div v-if="tags.length" class="job-card__button-area">
        <base-button small @click.native="EbbaModal({ui_job_selection:title})">Find similar jobs</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal'

export default {
  mixins: [ModalMixin],
  props: {
    icon: String,
    title: String,
    description: String,
    tags: {
      type: Array,
      required: true,
    },
    button: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss">
.job-card {
  user-select: none;
  position: relative;
  display: flex;
  &__body {
    background-color: #ffffff;
    border-radius: var(--border-radius-default);
    box-shadow: var(--box-shadow-default);
    padding: var(--padding-default);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    min-height: 190px;
  }
  &__text {
    flex-basis: 80%;
    margin-bottom: 1.5rem;
  }
  &__title {
    font-size: 1.5rem;
  }
  &__description {
    display: block;
    margin-bottom: 0.75rem;
  }
  &__icon {
    text-align: center;
    flex-basis: 20%;
    max-height: 55px;

    img {
      display: unset;
      border-radius: 4px;
      position: relative;
      max-height: 100%;
      top: -6px;
    }
  }
  &__tag-area {
      //@todo fix responsiveness
    flex-basis: 100%;
    white-space: nowrap;
    margin-bottom: .8rem;
  }
  &__tag {
    display: inline-block;
    color: #bcb8b8;
    padding: 0.5rem 0.8rem;
    font-weight: 600;
    font-size: 0.7rem;
    border-radius: 4px;
    border: 2px solid #c7c2c2;
    margin: 0 0 .35rem .375rem;

    &:first-child {
      margin-left: 0;
    }
  }
  &__button-area {
    flex-basis: 100%;
  }
}
</style>